// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-founders-index-js": () => import("./../../../src/pages/founders/index.js" /* webpackChunkName: "component---src-pages-founders-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-built-js": () => import("./../../../src/pages/portfolio/built.js" /* webpackChunkName: "component---src-pages-portfolio-built-js" */),
  "component---src-pages-portfolio-invested-js": () => import("./../../../src/pages/portfolio/invested.js" /* webpackChunkName: "component---src-pages-portfolio-invested-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */)
}

