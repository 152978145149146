const Sentry = require('@sentry/react')

exports.onClientEntry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV || 'development',
    // Netlify - https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
    release: __SENTRY_RELEASE__, // eslint-disable-line
    dsn: __SENTRY_DSN__, // eslint-disable-line
    enabled: process.env.NODE_ENV === 'production',
  })
  window.Sentry = Sentry
}
